import UnderConstruction from "@/components/UnderConstruction.vue"



const eventsRoute = {
    path: '/events',
    name: 'events',
    children: [
        {
            path: '',
            component: UnderConstruction
        },
    ]
}

export default eventsRoute
