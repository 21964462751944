import UnderConstruction from "@/components/UnderConstruction.vue"



const publicationRoute = {
    path: '/publication',
    name: 'publication',
    children: [
        {
            path: '',
            component: UnderConstruction
        },
    ]
}

export default publicationRoute
