<template>
  <div>
    <h1>Add New Author</h1>
    <form @submit.prevent="submitAuthor">
      <div class="mb-3">
        <label for="fullName" class="form-label">Full Name</label>
        <input
          type="text"
          id="fullName"
          v-model="newAuthor.full_name"
          class="form-control"
          required
        />
      </div>
      <div class="mb-3">
        <label for="user" class="form-label">User</label>
        <select id="user" v-model="newAuthor.user" class="form-control" required>
          <option value="" disabled>Select a user</option>
          <option value="null">None</option>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.first_name }} {{ user.last_name }}
          </option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary">Add Author</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

const token = localStorage.getItem('access');
export default {
  name: 'CreateAuthor',
  data() {
    return {
      newAuthor: {
        full_name: '',
        user: null
      },
      users: []
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/user/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.users = response.data.results;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async submitAuthor() {
      try {
        if (!token) {
          throw new Error('No token found');
        }
        await axios.post(`${process.env.VUE_APP_BASE_URL}/api/author/`, this.newAuthor, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.$router.push('/authors');
      } catch (error) {
        console.error('Error adding author:', error);
      }
    }
  }
};
</script>

<style scoped>
.mb-3 {
  margin-bottom: 1rem;
}

.form-label {
  margin-bottom: 0.5rem;
}
</style>
