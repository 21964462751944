<template>
  <header class="header_section">
    <div class="container">
      <nav class="navbar navbar-expand-lg custom_nav-container">
        <a class="navbar-brand" href="/">
          <span>IGZ</span>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
          <span class=""></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item"><a class="nav-link" href="/events">{{ $t('message.header.event') }}</a></li>
            <li class="nav-item"><a class="nav-link" href="/research">{{ $t('message.header.research') }}</a></li>
            <li class="nav-item"><a class="nav-link" href="/publication">{{ $t('message.header.publications') }}</a></li>
            <li class="nav-item"><a class="nav-link" href="/library">{{ $t('message.header.library') }}</a></li>
            <li class="nav-item"><a class="nav-link" href="/announcement">{{ $t('message.header.announcements') }}</a></li>
            <li class="nav-item"><a class="nav-link" href="/admin">{{ $t('message.header.admin') }}</a></li>
          </ul>
          <div class="language-dropdown">
            <button @click="toggleDropdown">{{ currentLang.toUpperCase() }} &#x25BC;</button>
            <ul v-if="dropdownOpen">
              <li v-for="lang in languages" :key="lang" @click="changeLanguage(lang)">{{ lang.toUpperCase() }}</li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>



<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'HeaderComponent',
  setup() {
    const { locale } = useI18n();
    const dropdownOpen = ref(false);
    const languages = ref(['en', 'ru', 'kk']);

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };

    const changeLanguage = (lang: string) => {
      locale.value = lang;
      localStorage.setItem('lang', lang);
      dropdownOpen.value = false;
    };

    return {
      dropdownOpen,
      languages,
      toggleDropdown,
      changeLanguage,
      currentLang: locale,
    };
  }
});
</script>


  
<style scoped>
.header_section {
  background-color: black;
  color: white;
}

.navbar-brand span {
  color: white;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  color: grey;
}

.user_link, .cart_link, .nav_search-btn, .order_online {
  color: white;
}

.user_link:hover, .cart_link:hover, .nav_search-btn:hover, .order_online:hover {
  color: grey;
}

.language-dropdown {
  position: relative;
  display: inline-block;
}

.language-dropdown button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.language-dropdown ul {
  display: none;
  position: absolute;
  background-color: white;
  color: black;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.language-dropdown ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.language-dropdown ul li:hover {
  background-color: #ddd;
}

.language-dropdown ul {
  display: block;
}
</style>
