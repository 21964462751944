import AdminDashboard from '@/pages/admin/AdminDashboard.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import AuthersList from '@/pages/admin/library/auther/Authers.vue'
import CreateAuthor from '@/pages/admin/library/auther/CreateAuther.vue'


const adminRoute = {
    path: '/admin',
    component: AdminLayout,
    meta: {requiredAuth: true},
    children: [
        {
            path: '',
            name: 'dashboard',
            component: AdminDashboard,
        },
        {
            path: 'authors',
            children: [
                {
                    path: '',
                    name: 'authors',
                    component: AuthersList,
                },
                {
                    path: 'create',
                    name: 'create-author',
                    component: CreateAuthor,
                }
            ]
        },
        {
            path: 'categories',
            name: 'categories',
            component: AdminDashboard,
        },
        {
            path: 'contents',
            name: 'contents',
            component: AdminDashboard,
        },
        {
            path: 'languages',
            name: 'languages',
            component: AdminDashboard,
        },
        {
            path: 'tags',
            name: 'tags',
            component: AdminDashboard,
        },
        {
            path: 'materials',
            name: 'materials',
            component: AdminDashboard,
        },
        {
            path: 'users',
            name: 'users',
            component: AdminDashboard,
        },
        {
            path: 'profile',
            name: 'profile',
            component: AdminDashboard,
        },
    ]
}

export default adminRoute
