export default {
  "message": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портал Исламских Научных Исследований"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doloremque, itaque aperiam facilis rerum..."])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["событие"])},
      "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["исследование"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["публикации"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["библиотека"])},
      "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["объявления"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["админ"])}
    },
    "news": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все новости"])}
    },
    "about": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О Портале Исламских Научных Исследований"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Существует много вариантов отрывков Lorem Ipsum, но большинство из них подверглись изменениям в той или иной форме, в том числе с добавлением юмора или случайных слов, которые выглядят не очень правдоподобно. Если вы собираетесь использовать отрывок Lorem Ipsum, убедитесь, что в середине текста нет ничего компрометирующего. Все"])}
    },
    "projects": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проекты"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте в курсе текущих работ iSAM"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все проекты"])}
    },
    "events": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["События"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все события"])}
    },
    "publications": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикации"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние публикации iSAM"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все публикации"])}
    },
    "clients": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что говорят наши клиенты"])}
    },
    "library": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Библиотека"])},
      "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каталог библиотеки"])},
      "search": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основной поиск"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор / Участник"])},
        "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место публикации / Издатель"])},
        "recently_added": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавно добавленные записи"])},
          "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать.."])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
          "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последнюю неделю"])},
          "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последний месяц"])},
          "three_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За последние три месяца"])}
        },
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер результата"])}
      },
      "material": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материалы"])},
        "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия:"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата публикации:"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория:"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание:"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык:"])}
      },
      "material_detail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок:"])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
        "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторы"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])}
      }
    },
    "global": {
      "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать далее"])},
      "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])}
    },
    "footer": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
      "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия"])},
      "mission_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия «IGZ» - создание и поддержание науки в исламе."])},
      "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часы работы"])},
      "everyday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый день"])}
    }
  }
}