import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import './assets/css/bootstrap.css';
import './assets/css/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/responsive.css';
import router from './router/index';
import store from './store/index';
import BootstrapVue3 from 'bootstrap-vue-3';
import i18n from './i18n';

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(BootstrapVue3)
  .mount('#app');
