<template>
    <div class="container d-flex flex-column justify-content-center align-items-center min-vh-100">
      <div class="text-center">
        <h1 class="display-4 mb-4">🚧 Under Construction 🚧</h1>
        <p class="lead mb-4">
          This page is still under development. We are working hard to get it ready.
        </p>
        <p class="mb-4">
          Check back later for updates!
        </p>
        <a href="/" class="btn btn-primary btn-lg">Go Back to Home</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UnderConstruction',
  };
  </script>
  
  <style scoped>
  .container {
    background-color: #f8f9fa;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #dc3545;
  }
  
  p {
    color: #6c757d;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  </style>
  