<template>
  <div class="material-detail container mt-5">
    <h1 class="text-center mb-4">IGZ {{ $t('message.library.name') }}</h1>
    <div v-if="material" class="row">
      <!-- Основные данные о материале -->
      <div class="col-md-6 mb-4">
        <div class="card border-0 bg-light shadow-sm p-4">
          <p class="card-text"><strong>{{ $t('message.library.material_detail.title') }}</strong> {{ material.title }}</p>
          <p class="card-text"><strong>{{ $t('message.library.material.series') }}</strong> {{ material.series }}</p>
          <p class="card-text"><strong>{{ $t('message.library.material.date') }}</strong> {{ material.date_of_publication }}</p>
          <p class="card-text"><strong>{{ $t('message.library.material.category') }}</strong> {{ material.category }}</p>
          <p class="card-text"><strong>{{ $t('message.library.material.content') }}</strong> {{ material.content }}</p>
          <p class="card-text"><strong>{{ $t('message.library.material.language') }}</strong> {{ material.language }}</p>
        </div>
      </div>
      <!-- Дополнительные данные -->
      <div class="col-md-6 mb-4">
        <div class="card border-0 bg-light shadow-sm p-4">
          <h4 class="card-subtitle mb-3">{{ $t('message.library.material_detail.tags') }}</h4>
          <ul class="list-group">
            <li v-for="(tag, index) in material.tags" :key="index" class="list-group-item">{{ tag }}</li>
          </ul>
        </div>
        <div class="card border-0 bg-light shadow-sm p-4 mt-4">
          <h4 class="card-subtitle mb-3">{{ $t('message.library.material_detail.authors') }}</h4>
          <ul class="list-group">
            <li v-for="(author, index) in material.authors" :key="index" class="list-group-item">{{ author }}</li>
          </ul>
        </div>
        <div class="card border-0 bg-light shadow-sm p-4 mt-4">
          <h4 class="card-subtitle mb-3">{{ $t('message.library.material_detail.files') }}</h4>
          <ul class="list-group">
            <li v-for="(file, index) in material.files" :key="index" class="list-group-item">
              <a :href="file" target="_blank">{{ file }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["id"],
  data() {
    return {
      material: null,
    };
  },
  created() {
    this.fetchMaterial();
  },
  methods: {
    async fetchMaterial() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/api/material/${this.id}`
        );
        this.material = response.data;
      } catch (error) {
        console.error("Error fetching material:", error);
      }
    },
  },
};
</script>

<style scoped>
.material-detail {
  padding: 20px;
}

.material-detail h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.card {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.card-title {
  font-size: 1.5em;
  margin-bottom: 1rem;
}

.card-subtitle {
  font-size: 1.25em;
  color: #007bff;
}

.list-group-item {
  background-color: #f8f9fa;
}

.list-group-item a {
  text-decoration: none;
  color: #007bff;
}

.list-group-item a:hover {
  text-decoration: underline;
}
</style>
