import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
  state: {
    materials: []
  },
  mutations: {
    setMaterials(state, materials) {
      state.materials = materials;
    }
  },
  actions: {
    async fetchMaterials({ commit }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/api/material/`);
        commit('setMaterials', response.data.results);
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    }
  }
});

export default store;
