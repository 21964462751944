<template>
  <div>
    <h1>
        This is login page
    </h1>
  </div>
</template>

<script>
export default {
    name: 'LoginPage',
}
</script>

<style>

</style>