<template>
    <div class="d-flex flex-column flex-md-row">
      <nav class="sidebar bg-light flex-shrink-0">
        <div class="user-info p-3 text-center">
          <img :src="user.avatar || 'default-avatar.png'" alt="User Avatar" class="avatar mb-3" />
          <p class="mb-1"><strong>{{ user.first_name }} {{ user.last_name }}</strong></p>
          <p class="mb-1">{{ user.email }}</p>
          <p class="mb-1">{{ user.phone_number }}</p>
        </div>
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <strong>Library</strong>
                </button>
              </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
              <div class="card-body">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'authors' }">Authors</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'categories' }">Categories</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'contents' }">Contents</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'languages' }">Languages</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'tags' }">Tags</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'materials' }">Materials</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'users' }">Users</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'profile' }">Profile</router-link>
                </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="content p-4">
        <router-view />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AdminLayout",
    data() {
      return {
        user: {
          avatar: null,
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
        },
      };
    },
    created() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        this.user = user;
      }
    },
    beforeRouteEnter(to, from, next) {
      const isAuthenticated = localStorage.getItem("authenticated") === "true";
      if (!isAuthenticated) {
        next({ name: "login" });
      } else {
        next();
      }
    },
  };
  </script>
  
  <style scoped>
  .d-flex {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    height: 100vh;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
  }
  
  .content {
    flex-grow: 1;
  }
  
  .nav-link {
    padding: 15px;
    font-size: 1.1em;
  }
  
  .nav-link.active {
    background-color: #007bff;
    color: white;
  }
  
  .nav-link:hover {
    background-color: #e9ecef;
  }
  
  .user-info {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    background-color: #f8f9fa;
  }
  
  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  </style>
  