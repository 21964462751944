import UnderConstruction from "@/components/UnderConstruction.vue"



const announcemetRoute = {
    path: '/announcement',
    name: 'announcement',
    children: [
        {
            path: '',
            component: UnderConstruction
        },
    ]
}

export default announcemetRoute
