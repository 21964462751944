export default {
  "message": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ислам Ғылымы Зерттеу Порталы"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doloremque, itaque aperiam facilis rerum..."])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оқиға"])},
      "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зерттеу"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жарияланымдар"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кітапхана"])},
      "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хабарландырулар"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["әкімші"])}
    },
    "news": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңалықтар"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық жаңалықтар"])}
    },
    "about": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз туралы"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ислам Ғылымы Зерттеу Порталы туралы"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem Ipsum үзінділерінің көптеген нұсқалары бар, бірақ олардың көпшілігі әртүрлі өзгерістерге ұшыраған, әзіл немесе кездейсоқ сөздер қосылып, сенімді көрінбейтін. Егер сіз Lorem Ipsum үзіндісін пайдаланғыңыз келсе, мәтіннің ортасында ештеңе ұят емес екеніне көз жеткізуіңіз керек. Барлығы"])}
    },
    "projects": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жобалар"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iSAM-ның ағымдағы жұмыстары туралы хабардар болыңыз"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық жобалар"])}
    },
    "events": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іс-шаралар"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық іс-шаралар"])}
    },
    "publications": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарияланымдар"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iSAM-ның соңғы жарияланымдары"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық жарияланымдар"])}
    },
    "clients": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікірлер"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің клиенттер не дейді"])}
    },
    "library": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кітапхана"])},
      "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кітапхана каталогы"])},
      "search": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі іздеу"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор / Қатысушы"])},
        "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жариялау орны / Баспагер"])},
        "recently_added": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жақында қосылған жазбалар"])},
          "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау.."])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығы"])},
          "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы аптада"])},
          "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы айда"])},
          "three_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы үш айда"])}
        },
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтиже нөмірі"])}
      },
      "material": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материалдар"])},
        "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия:"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарияланған күні:"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат:"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мазмұны:"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тілі:"])}
      },
      "material_detail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тақырып:"])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегтер"])},
        "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторлар"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдар"])}
      }
    },
    "global": {
      "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ оқу"])},
      "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тазалау"])}
    },
    "footer": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізбен байланысыңыз"])},
      "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия"])},
      "mission_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'IGZ' миссиясы - исламда ғылымды құру және қолдау."])},
      "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс уақыты"])},
      "everyday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күн сайын"])}
    }
  }
}