<template>
    <footer class="footer_section">
      <div class="container">
        <div class="row">
          <div class="col-md-4 footer-col">
            <div class="footer_contact">
              <h4>{{ $t('message.footer.contact') }}</h4>
              <div class="contact_link_box">
                <a href="">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>Проспект Аль-Фараби, 73д</span>
                </a>
                <a href="">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>+7 747 803 7238</span>
                </a>
                <a href="">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>izgportal@gmail.com</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 footer-col">
            <div class="footer_detail">
              <a href="" class="footer-logo">{{ $t('message.footer.mission') }}</a>
              <p>
                {{ $t('message.footer.mission_text') }}
              </p>
            </div>
          </div>
          <div class="col-md-4 footer-col">
            <h4>{{ $t('message.footer.opening') }}</h4>
            <p>{{ $t('message.footer.everyday') }}</p>
            <p>10.00 Am - 6.00 Pm</p>
          </div>
        </div>
        <div class="footer-info">
          <p>
            &copy; <span id="displayYear">{{ displayYear }}</span> Distributed By
            <a href="https://www.nmu.edu.kz/en/bas-bet-english/" target="_blank">Nur-Mubarak</a>
          </p>
        </div>
      </div>
    </footer>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  
  export default defineComponent({
    name: 'FooterComponent',
    setup() {
      const displayYear = ref<number>(new Date().getFullYear());
  
      return { displayYear };
    }
  });
  </script>
  
  <style scoped>
  /* Add your scoped CSS here */
  </style>
  