<template>
  <div class="app-container">
    <HeaderComponent v-if="showHeader" />

    <div class="main-content">
      <router-view/> 
    </div>

    <FooterComponent />
  </div>
</template>

<script lang="ts">

import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderComponent from '@/components/Header.vue'
import FooterComponent from '@/components/Footer.vue';

export default defineComponent({
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  setup() {
    const route = useRoute();

    const showHeader = computed(() => {
      return route.path !== '/';
    });

    return {
      showHeader
    };
  }
});
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
}
</style>
