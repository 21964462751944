import login from '@/pages/auth/login.vue'
import register from '@/pages/auth/register.vue'


const authRoute = {
    path: '/auth',
    name: 'auth',
    redirect: '/auth/login',
    component: () => import('@/pages/auth/login.vue'),
    children: [
        {
            path: 'login',
            name: 'LoginPage',
            component: login,
            meta: {
                requiredAuth: false
            }
        },
        {
            path:'register',
            name:'RegisterPage',
            component: register,
            meta: {
                requiredAuth: false
            }
        }
    ]
}

export default authRoute
