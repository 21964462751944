import librarySearch from '@/pages/library/index.vue';
import materialsPage from '@/pages/library/Material.vue';
import MaterialDetail from '@/pages/library/MaterialDetail.vue';


const libraryRoute = {
    path: '/library',
    name: 'library',
    redirect: '/auth/login',
    children: [
        {
            path: '',
            name: 'librarySearch',
            component: librarySearch
        },
        {
            path: 'materials',
            name: 'materialsList',
            component: materialsPage
        },
        { 
            path: '/material/:id', 
            name: 'MaterialDetail',
            component: MaterialDetail, 
            props: true 
        }
    ]
}

export default libraryRoute
