import UnderConstruction from "@/components/UnderConstruction.vue"



const researchRoute = {
    path: '/research',
    name: 'research',
    children: [
        {
            path: '',
            component: UnderConstruction
        },
    ]
}

export default researchRoute
