<template>
    <div>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h1>Authors List</h1>
        <router-link :to="{ name: 'create-author' }" class="btn btn-primary nav-link">Add Author</router-link>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Full Name</th>
            <th scope="col">Is Authenticated</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="author in authors" :key="author.id">
            <td>{{ author.id }}</td>
            <td>{{ author.full_name }}</td>
            <td>
              <i v-if="author.user" class="fas fa-check-circle text-success"></i>
              <i v-else class="fas fa-times-circle text-danger"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: !pagination.prev }">
            <button class="page-link" @click="fetchAuthors(pagination.prev)" :disabled="!pagination.prev">Previous</button>
          </li>
          <li class="page-item" :class="{ disabled: !pagination.next }">
            <button class="page-link" @click="fetchAuthors(pagination.next)" :disabled="!pagination.next">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AuthersList',
    data() {
      return {
        authors: [],
        pagination: {
          prev: null,
          next: null
        }
      };
    },
    created() {
      this.fetchAuthors(`${process.env.VUE_APP_BASE_URL}/api/author/?page%5Bsize%5D=15`);
    },
    methods: {
      async fetchAuthors(url) {
        try {
          const response = await axios.get(url);
          this.authors = response.data.results;
          this.pagination.prev = response.data.links.prev;
          this.pagination.next = response.data.links.next;
        } catch (error) {
          console.error('Error fetching authors:', error);
        }
      },
    }
  };
  </script>
  
  <style scoped>
  .d-flex {
    display: flex;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .mb-3 {
    margin-bottom: 1rem;
  }
  
  .table {
    width: 100%;
    margin-top: 20px;
  }
  
  .pagination {
    margin-top: 20px;
  }
  
  .page-link {
    cursor: pointer;
  }
  </style>
  