<template>
  <div class="materials-container">
    <h1 class="materials-title">{{ $t('message.library.material.title') }}</h1>
    <div v-for="material in materials" :key="material.id" class="material-card" @click="goToDetail(material.id)">
      <div class="title">
        <p><strong>{{ material.title }}</strong></p>
      </div>
      <div class="details">
        <p class="detail-item"><strong>{{ $t('message.library.material.series') }}</strong> {{ material.series }}</p>
        <p class="detail-item"><strong>{{ $t('message.library.material.date') }}</strong> {{ material.date_of_publication }}</p>
        <p class="detail-item"><strong>{{ $t('message.library.material.category') }}</strong> {{ material.category }}</p>
        <p class="detail-item"><strong>{{ $t('message.library.material.content') }}</strong> {{ material.content }}</p>
        <p class="detail-item"><strong>{{ $t('message.library.material.language') }}</strong> {{ material.language }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaterialsPage',
  computed: {
    materials() {
      return this.$store.state.materials;
    }
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ path: `/material/${id}` });
    }
  }
};
</script>

<style scoped>
.materials-container {
  max-width: 1200px; /* Увеличить ширину контейнера */
  margin: 0 auto;
  padding: 20px;
}

.materials-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5em; /* Увеличить размер заголовка */
  color: #333;
}

.material-card {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0; /* Серый фон */
  border: none; /* Убрать обводку */
  padding: 15px;
  margin: 15px 0;
  border-radius: 8px; /* Скругление углов */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Плавное изменение фона */
}

.material-card:hover {
  background-color: #e0e0e0; /* Цвет фона при наведении */
}

.title {
  font-size: 1.8em; /* Увеличить размер шрифта заголовка в карточке */
  margin-bottom: 15px;
}

.details {
  display: flex;
  flex-direction: column;
}

.detail-item {
  margin-bottom: 10px;
}
</style>