export default {
  "message": {
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islamic Science Research Portal"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doloremque, itaque aperiam facilis rerum..."])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event"])},
      "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["research"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publications"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])},
      "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["announcement"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin"])}
    },
    "news": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All news"])}
    },
    "about": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Islamic Science Research Portal"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All"])}
    },
    "projects": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Updated About iSAM's Ongoing Works"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All projects"])}
    },
    "events": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All event"])}
    },
    "publications": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest iSAM Publications"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All publications"])}
    },
    "clients": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What Says Our Customers"])}
    },
    "library": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
      "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library Catalog"])},
      "search": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Search"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author / Contributor"])},
        "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Publication / Publisher"])},
        "recently_added": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently Added Entries"])},
          "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select.."])},
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last week"])},
          "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last month"])},
          "three_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last three months"])}
        },
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Number"])}
      },
      "material": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
        "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series:"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Publication:"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category:"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content:"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language:"])}
      },
      "material_detail": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title:"])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authors"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])}
      }
    },
    "global": {
      "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
      "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
    },
    "footer": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
      "mission_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IGZ's mission is to create and maintain science in Islam."])},
      "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Hours"])},
      "everyday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every Day"])}
    }
  }
}