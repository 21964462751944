import { createRouter, createWebHistory } from 'vue-router';
import libraryRoute from './library/';
import adminRoute from './admin/';
import authRoute from './auth/';
import announcementRoute from './announcements/';
import eventsRoute from './event';
import researchRoute from './research/';
import publicationRoute from './publications/';
import LoginComponent from '@/pages/admin/Login.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/index.vue')
  },
  authRoute,
  adminRoute,
  libraryRoute,
  announcementRoute,
  eventsRoute,
  researchRoute,
  publicationRoute,
  {
    path: '/login',
    name: 'login',
    component: LoginComponent,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('authenticated') === 'true';

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
