import { createI18n, LocaleMessages } from 'vue-i18n'


interface MessageSchema {
  message: {}
}

type LocaleMessagesType = {
  [key: string]: MessageSchema
}

function loadLocaleMessages(): LocaleMessagesType {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessagesType = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  console.log(messages)
  return messages
}

const i18n = createI18n({
  locale: (localStorage.getItem('lang') || 'kk'),
  fallbackLocale: 'en',
  messages: loadLocaleMessages() as any
})

export default i18n
