import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49b5219e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_HeaderComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_FooterComponent)
  ]))
}