<template>
  <div class="container-sm mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8 col-sm-10">
        <form @submit.prevent="handleSearch" @reset="handleClear">
          <h2 class="text-center mb-4">{{ $t('message.library.catalog') }} - {{ $t('message.library.search.type') }}</h2>
          <div class="form-group mb-3">
            <label for="inputTitle">{{ $t('message.library.search.title') }}</label>
            <input
              type="text"
              class="form-control"
              id="inputTitle"
              v-model="searchCriteria.title"
              :placeholder="$t('message.library.search.title')"
            />
          </div>
          <div class="form-group mb-3">
            <label for="inputAuthor">{{ $t('message.library.search.author') }}</label>
            <input
              type="text"
              class="form-control"
              id="inputAuthor"
              v-model="searchCriteria.author"
              :placeholder="$t('message.library.search.author')"
            />
          </div>
          <div class="form-group mb-3">
            <label for="inputPlace">{{ $t('message.library.search.publication') }}</label>
            <input
              type="text"
              class="form-control"
              id="inputPlace"
              v-model="searchCriteria.place"
              :placeholder="$t('message.library.search.publication')"
            />
          </div>
          <div class="form-group mb-3">
            <label for="inputRecentlyAdded">{{ $t('message.library.search.recently_added.title') }}</label>
            <select
              class="form-select"
              id="inputRecentlyAdded"
              v-model="searchCriteria.recentlyAdded"
            >
              <option value="">{{ $t('message.library.search.recently_added.select') }}</option>
              <option value="all">{{ $t('message.library.search.recently_added.all') }}</option>
              <option value="week">{{ $t('message.library.search.recently_added.week') }}</option>
              <option value="month">{{ $t('message.library.search.recently_added.month') }}</option>
              <option value="three-month">{{ $t('message.library.search.recently_added.three_month') }}</option>
            </select>
          </div>
          <div class="form-group mb-3">
            <label for="inputResultNumber">{{ $t('message.library.search.number') }}</label>
            <select
              class="form-select"
              id="inputResultNumber"
              v-model="searchCriteria.resultNumber"
            >
              <option value="">{{ $t('message.library.search.recently_added.select') }}</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <div class="d-flex flex-column gap-2 mb-4">
            <button type="submit" class="btn btn-primary w-100">{{ $t('message.global.find') }}</button>
            <button type="reset" class="btn btn-secondary w-100">{{ $t('message.global.clear') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LibraryCatalogSearch",
  data() {
    return {
      searchCriteria: {
        title: '',
        author: '',
        place: '',
        recentlyAdded: '',
        resultNumber: ''
      }
    };
  },
  methods: {
    async handleSearch() {
      try {
        await this.$store.dispatch('fetchMaterials');
        this.$router.push({ name: 'materialsList' });
      } catch (error) {
        console.error('Error fetching materials:', error);
      }
    },
    handleClear() {
      this.searchCriteria = {
        title: '',
        author: '',
        place: '',
        recentlyAdded: '',
        resultNumber: ''
      };
    }
  }
};
</script>

<style scope>
/* Ваши стили здесь */
</style>